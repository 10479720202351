.home_top_wrap{
    display: flex;
    justify-content: space-between;
}
.home_top_wrap:nth-child(1){
    margin-bottom: 30px;
}
.home_top_wrap .home_top_block{
    padding: 35px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #0027761C;
    border-radius: 8px;
}
.home_top_wrap .home_top_block:nth-child(1){
    width: 49%;
}
.home_top_wrap .home_top_block:nth-child(2){
    width: 49%;
    background-image: url(../../../assets/images/seafood7-element.svg);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
.home_top_wrap .home_top_block:nth-child(1) p{
    font-size: 24px;
    color: #404855;
    margin: 0px;
    margin-bottom: 10px;
}
.home_top_wrap .home_top_block:nth-child(1) label{
    font-size: 14px;
    color: #404855;
    font-family: 'Poppins-Light';
}
.home_bt_out_wp{
    display: flex;
    margin-top: 25px;
    position: relative;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.button_circle{
    background-color: #00FFFF26;
    color: #002776;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    text-align: center;
}
.no_price{
    text-align: center;
}
.home_btn{
    background-color: #00FFFF2D;
    background: #00FFFF2D;
    border-radius: 4px;
    color: #002776;
    font-size: 16px;
    padding: 13px 25px;
    cursor: pointer;
    transition: all ease 0.5s;
}
.home_btn:nth-child(1){
    margin-right: 20px;
}

.button_circle:hover,.home_btn:hover{
    background-color: #002776;
    box-shadow: -1px 0px 7px 2px #0027764f;
    color: white;
    transition: all ease 0.5s;
}
.home_blk_stat{
    margin-top: 30px;
}
.home_blk_stat:nth-child(1){
    margin-right: 60px;
}
.home_blk_price label{
    color: #002776;
}
.home_blk_price p{
    color: #58637A;
    font-size: 24px;
    font-family: 'Poppins-Bold';
    margin-top: 5px;
}
.home_blk_price_wrap{
    display: flex;
}
.home_blk_price{
    margin-right: 45px;
}
.home_blk_stat_wrap{
    display: flex;
    align-items: flex-end;
}
.count_tag{
    background-color: #0027762B;
    color: #002776;
    display: inline-block;
    padding: 1px 10px;
    border-radius: 26px;
}
.home_blk_stat_wrap p{
    color: #002776;
    font-size: 26px;
    font-family: 'Poppins-Light';
    margin: 15px 0px;
}
.home_blk_stat_wrap label{
    color: #58637A;
    font-size: 16px;
    font-family: 'Poppins-Light';
}
.home_conf_outer{
    width: 100%;
    display: flex;
}
.home_conf_status{
    background-color: #2BC0BA;
    color: white;
    padding: 2px 20px;
    border-radius: 36px; 
    margin-top: 15px;
    margin-right: 20px;
}
.req_status{
    background-color: #FFD695;
}

.sp_btn,.revReq{
    background-color: #007681 !important;
    color: white !important;
}


/* media_query_starts */
@media only screen and (max-width: 1680px) {
    .home_top_wrap .home_top_block:nth-child(1) p{
        font-size: 20px;
    }
    .home_top_wrap .home_top_block:nth-child(1) label{
        font-size: 12px;
    }
    .home_btn{
        font-size: 14px;
    }
    .home_blk_price label{
        font-size: 12px;
    }
    .home_blk_price p{
        font-size: 20px;
    }
    .count_tag{
        font-size: 12px;
    }
    .home_blk_stat_wrap p{
        font-size: 22px;
    }
    .home_blk_stat_wrap label{
        font-size: 14px;
    }
    .home_conf_status{
        font-size: 12px;
    }

}
@media only screen and (max-width: 1390px) {
    .home_top_wrap .home_top_block{
        padding: 25px;
    }
    .home_btn{
        padding: 10px 20px;
    }
    .home_blk_price{
        margin-right: 30px;
    }
    .home_blk_price p {
        font-size: 18px;
    }
    .home_blk_stat_wrap p{
        font-size: 20px;
    }
    .home_blk_stat_wrap label{
        font-size: 12px;
    }
    .home_blk_stat:nth-child(1){
        margin-right: 20px;
    }
    .home_blk_stat{
        margin-top: 20px;
    }
}




